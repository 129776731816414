import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'gatsby'

const GetInTouchView = ({ locale, pageContent }) => {
  return (
    <div className='contact-page'>
      <Container className='bg-header px-0' fluid>
        <Container className='py-5'>
          <Row className='justify-content-center pt-5 mt-5'>
            <Col className='text-center col-10 my-5 my-md-4' lg='5'>
              <h1>{pageContent.title}</h1>
            </Col>
          </Row>
          <Row className='justify-content-center mb-3 my-md-5'>
            <Col className='col-12 mb-3 mb-md-0' md='3'>
              <p className='h5'>{pageContent.methodTitles[0]}</p>
              <Button
                as={Link}
                to={`/${locale.toLowerCase()}/pricing`}
                className='px-5 py-3 mt-1'
                variant='outline-warning'
              >
                {pageContent.button1}
              </Button>
            </Col>
            <Col
              className='col-12 d-flex justify-content-center align-items-center'
              md='1'
            >
              <hr />
            </Col>
            <Col className='col-12 mb-3 mb-md-0' md='4'>
              <p className='h5 mt-3 mt-lg-0'>{pageContent.methodTitles[1]}</p>
              <p
                className='mt-1'
                dangerouslySetInnerHTML={{ __html: pageContent.bodyText1 }}
              ></p>
              <p
                className='mt-1 pt-2'
                dangerouslySetInnerHTML={{ __html: pageContent.bodyText2 }}
              ></p>
            </Col>
            <Col
              className='col-12 d-flex justify-content-center align-items-center'
              md='1'
            >
              <hr />
            </Col>
            <Col className='col-12 pr-md-0' md='3'>
              <p className='h5 mt-3 mt-lg-0'>{pageContent.methodTitles[2]}</p>
              <p className='mt-1 mb-2'>{pageContent.bodyText3}</p>
              <Button
                as='a'
                href={`/${locale.toLowerCase()}/book-free-consult`}
                className='px-5 py-3 mt-1'
                variant='outline-warning'
                color='primary'
              >
                {pageContent.button2}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}

export default GetInTouchView
