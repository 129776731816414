import React, { useContext } from 'react'
import Layout from '../components/Global/Layout'
import { graphql } from 'gatsby'
import GetInTouch from '../components/GetInTouch'
import Loader from '../components/Global/Loader'
import { GlobalStateContext } from '../context/GlobalContextProvider'

export const query = graphql`
  {
    allContentfulContactPage {
      edges {
        node {
          title
          methodTitles
          button1
          button2
          bodyText1
          bodyText2
          bodyText3
          node_locale
        }
      }
    }
  }
`

const GetInTouchPage = (props) => {
  const state = useContext(GlobalStateContext)

  if (!state.locale) {
    return (
      <Layout locale={state.locale}>
        <Loader loading />
      </Layout>
    )
  }

  const faqData = props.data?.allContentfulContactPage.edges?.filter(
    (node) => node.node.node_locale === state.locale
  )[0]?.node

  return (
    <Layout locale={state.locale} url='/get-in-touch' title='Get in touch'>
      <GetInTouch locale={state.locale} pageContent={faqData} />
    </Layout>
  )
}

export default GetInTouchPage
